import { ServiceHook, ServiceHookList, ServiceHookTestModel, ServiceHookTestResultsModel, ServiceHookUpdateModel } from '@/models/service-hook-maintenance.d';
import { PaginationOptions } from '@/models/hal.d';
import config from '@/config';

import { pagedUrl } from './helpers';
import AxiosService from './axios-service';
import { CancelToken } from 'axios';

export default class ServiceHookService {
  axiosService: AxiosService;

  private defaultPaginationOptions: PaginationOptions = {
    page: 1,
    size: config.defaultPageSize,
  };

  constructor(injectedAxios: AxiosService) {
    this.axiosService = injectedAxios;
  }

  async createServiceHook(serviceHook: ServiceHookUpdateModel): Promise<ServiceHook> {
    const uri = `${config.api}/service-hooks`;

    const newServiceHook = await this.axiosService.post(uri, serviceHook);

    return newServiceHook as ServiceHook;
  }

  async readSingle(serviceHookId: string): Promise<ServiceHook> {
    const uri = `${config.api}/service-hooks/${serviceHookId}`;

    const serviceHook = await this.axiosService.get(uri);

    return serviceHook as ServiceHook;
  }

  async listServiceHooks(
    options = this.defaultPaginationOptions,
    cancelToken?: CancelToken
  ): Promise<ServiceHookList> {
    const uri = pagedUrl(`${config.api}/service-hooks`, options);
    const list = await this.axiosService.get(uri, { cancelToken });

    return list as ServiceHookList;
  }

  async updateServiceHook(
    serviceHookId: string,
    serviceHook: ServiceHookUpdateModel,
  ): Promise<ServiceHook> {
    const uri = `${config.api}/service-hooks/${serviceHookId}`;

    const updatedServiceHook = await this.axiosService.put(uri, serviceHook);

    return updatedServiceHook as ServiceHook;
  }

  async deleteServiceHook(serviceHookId: string): Promise<any> {
    if (!serviceHookId) return Promise.reject(new Error('No service hook id specified.'));

    const uri = `${config.api}/service-hooks/${serviceHookId}`;

    return await this.axiosService.delete(uri);
  }

  async testServiceHook(
    serviceHookId: string,
    testModel: ServiceHookTestModel
  ): Promise<ServiceHookTestResultsModel> {
    const uri = `${config.api}/service-hooks/${serviceHookId}/test`;

    const testResult = await this.axiosService.post(uri, testModel);

    return testResult as ServiceHookTestResultsModel;
  }
}
