import Vue from 'vue';
import Vuetify from 'vuetify';
import theme from './theme';
import 'vuetify/src/styles/main.sass';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'mdi',
  theme,
});
