export type KeywordSearchTerm = {
  name: string;
  appearenceInCommodity: number;
  appearenceInDangerousCargo: number;
  allAppearence: number;
};

export type KeywordSuggestionResult = {
  searchedTerm: KeywordSearchTerm;
  nextWords: KeywordSearchTerm[];
  previousWords: KeywordSearchTerm[];
};
export const KEYWORD_TYPES = {
  nextWord: "nextWord",
  previousWord: "previousWord",
  original: "original"
} as const;
export type Type = typeof KEYWORD_TYPES[keyof typeof KEYWORD_TYPES];
export type KeywordWithType = KeywordSearchTerm & { type: Type };

export type Sort = "f" | "c" | "d";
export const SortBy = {
  Frequency: "f",
  NonDG: "c",
  DangerousCargo: "d"
} as const;

export const SEARCH_TYPES: Readonly<{
  value: Sort;
  text: string;
  sortFn: (a: KeywordWithType, b: KeywordWithType) => number;
  getFn: (a: KeywordWithType) => number;
  color: string;
}>[] = [
  {
    value: "f",
    text: "Frequency",
    sortFn: (a, b) => b.allAppearence - a.allAppearence,
    getFn: (x: KeywordWithType) => x.allAppearence,
    color: "teal"
  },
  {
    value: "c",
    text: "Non-DG",
    sortFn: (a, b) => b.appearenceInCommodity - a.appearenceInCommodity,
    getFn: (x: KeywordWithType) => x.appearenceInCommodity,
    color: "indigo"
  },
  {
    value: "d",
    text: "Dangerous Cargo",
    getFn: (x: KeywordWithType) => x.appearenceInDangerousCargo,
    sortFn: (a, b) => b.appearenceInDangerousCargo - a.appearenceInDangerousCargo,
    color: "orange"
  }
];
