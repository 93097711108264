import AxiosService from '@/services/axios-service';
import uriTemplate from 'url-template';
import config from '@/config';
import { PaginationOptions } from '@/models/hal.d';
import { Template, TemplateList, TemplateUpsertModel } from '@/models/library-maintenance.d';
import { CancelToken } from 'axios';
export default class TemplateService {
  defaultPagenationOptions: PaginationOptions = {
    page: 1,
    size: config.defaultPageSize,
  };

  axiosService: AxiosService;

  constructor(injectedAxios: AxiosService) {
    this.axiosService = injectedAxios;
  }

  createTemplate(libraryId: string, template: TemplateUpsertModel): Promise<Template> {
    const uri = `${config.api}/libraries/${libraryId}/templates`;

    return this.axiosService.post(uri, template);
  }

  async listTemplates(
    libraryId: string,
    options = this.defaultPagenationOptions,
    filter?: string,
    cancelToken?: CancelToken
  ): Promise<TemplateList> {
    const { defaultPageSize, api } = config;
    const template = uriTemplate.parse(`${api}/libraries/${libraryId}/templates/{?page,size,sort*,filter}`);

    const uri = template.expand({
      ...{ page: 1, size: defaultPageSize, sort: [] },
      ...options,
      filter: filter && filter.length > 0 ? filter : undefined,
    });

    const list = await this.axiosService.get(uri, { cancelToken });

    return list as TemplateList;
  }

  async readSingle(
    libraryId: string,
    templateId: string,
    cancelToken?: CancelToken
  ): Promise<Template> {
    const uri = `${config.api}/libraries/${libraryId}/templates/${templateId}`;

    const template = await this.axiosService.get(uri, { cancelToken });

    return template as Template;
  }

  async deleteTemplate(libraryId: string, templateId: string): Promise<any> {
    if (!libraryId) return Promise.reject(new Error('No library id specified.'));
    if (!templateId) return Promise.reject(new Error('No template id specified.'));

    const uri = `${config.api}/libraries/${libraryId}/templates/${templateId}`;
    return this.axiosService.delete(uri);
  }

  updateTemplate(
    libraryId: string,
    templateId: string,
    template: TemplateUpsertModel,
  ): Promise<Template> {
    const uri = `${config.api}/libraries/${libraryId}/templates/${templateId}`;

    return this.axiosService.put(uri, template);
  }
}
