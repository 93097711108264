import { RouteConfig } from 'vue-router/types/router.d';
import { Config, configLoading } from '@/config';

import Home from '../views/Home.vue';

const hazcheckDetectRoutes: RouteConfig[] = [
  {
    path: '/',
    name: 'root',
    component: Home,
    meta: {
      title: 'Welcome',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: (() => import('@/views/Login.vue')),
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/libraries',
    name: 'libraries',
    component: (() => import('@/views/Libraries.vue')),
    meta: {
      title: 'Libraries',
      isSecure: true,
    },
  },
  {
    path: '/libraries/create',
    name: 'library-create',
    component: (() => import('@/views/Library.vue')),
    meta: {
      isSecure: true,
      title: 'Create Library',
    },
  },
  {
    path: '/libraries/:id',
    name: 'library',
    component: (() => import('@/views/Library.vue')),
    meta: {
      isSecure: true,
      title: 'Library',
    },
  },
  {
    path: '/libraries/:libraryId/templates/',
    name: 'templates',
    component: (() => import('@/views/Templates.vue')),
    meta: {
      isSecure: true,
      title: 'Templates',
    },
  },
  {
    path: '/libraries/:libraryId/templates/create',
    name: 'template-create',
    component: (() => import('@/views/Template.vue')),
    meta: {
      isSecure: true,
      title: 'Create Template',
    },
  },
  {
    path: '/libraries/:libraryId/templates/:templateId',
    name: 'template',
    component: (() => import('@/views/Template.vue')),
    meta: {
      isSecure: true,
      title: 'Template',
    },
  },
  {
    path: '/libraries/:libraryId/templates/:templateId/operations',
    name: 'template-operations',
    component: (() => import ('@/views/TemplateOperations.vue')),
    meta: {
      isSecure: true,
      title: 'Template Operations',
    }
  },
  {
    path: '/libraries/:libraryId/rules/',
    name: 'rules',
    component: (() => import('@/views/Rules.vue')),
    meta: {
      isSecure: true,
      title: 'Rules',
    },
  },
  {
    path: '/libraries/:libraryId/rules/create',
    name: 'rule-create',
    component: (() => import('@/views/Rule.vue')),
    meta: {
      isSecure: true,
      title: 'Create Rule',
    },
  },
  {
    path: '/libraries/:libraryId/rules/:ruleId',
    name: 'rule',
    component: (() => import('@/views/Rule.vue')),
    meta: {
      isSecure: true,
      title: 'Rule',
    },
  },
  {
    path: '/service-hooks',
    name: 'service-hooks',
    component: (() => import('@/views/ServiceHooks.vue')),
    meta: {
      isSecure: true,
      title: 'ServiceHooks',
    },
  },
  {
    path: '/service-hooks/create',
    name: 'service-hook-create',
    component: (() => import('@/views/ServiceHook.vue')),
    meta: {
      isSecure: true,
      title: 'Create Service Hook',
    },
  },
  {
    path: '/service-hooks/:id',
    name: 'service-hook',
    component: (() => import('@/views/ServiceHook.vue')),
    meta: {
      isSecure: true,
      title: 'ServiceHook',
    },
  },
  {
    path: '/policies/',
    name: 'policies',
    component: (() => import('@/views/Policies.vue')),
    meta: {
      isSecure: true,
      title: 'Policies',
    },
  },
  {
    path: '/policies/create',
    name: 'policy-create',
    component: (() => import('@/views/Policy.vue')),
    meta: {
      isSecure: true,
      title: 'Create Policy',
    },
  },
  {
    path: '/policies/:id',
    name: 'policy',
    component: (() => import('@/views/Policy.vue')),
    meta: {
      isSecure: true,
      title: 'Policy',
    },
  },
  {
    path: '/policies/:id/history',
    name: 'policy-history',
    component: (() => import('@/views/History.vue')),
    meta: {
      isSecure: true,
      title: 'Policy History',
      entityType: "policies"
    },
  },
  {
    path: '/screen-results',
    name: 'screen-results',
    component: (() => import('@/views/ScreenResults.vue')),
    meta: {
      isSecure: true,
      title: 'Screen Results',
    },
  },
  {
    path: '/screen-results/:id',
    name: 'screen-result',
    component: (() => import('@/views/ScreenResult.vue')),
    meta: {
      isSecure: true,
      title: 'Screen Result',
    },
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: (() => import('@/views/Configuration.vue')),
    meta: {
      isSecure: true,
      title: 'Configuration',
    },
  },
  {
    path: '/upload',
    name: 'upload',
    component: (() => import('@/views/UploadFromFile.vue')),
    meta: {
      isSecure: true,
      title: 'Upload',
    },
  }
];

const notFoundRoute: RouteConfig = {
  name: 'not-found',
  path: '*',
  redirect: '/',
};

function caseManagementRoutes(config: Config): RouteConfig[] {
  return ['cases', 'email-templates'].map((name) => ({
    path: `/${name}`,
    name,
    beforeEnter: () => {
      window.location.href = `${config.caseManagementUrl}/#/${name}`;
    },
  }));
}

function analyticsRoutes(config: Config): RouteConfig[] {
  return ['analytics','analytics-beta'].map((name) => ({
    path: `/${name}`,
    name,
    beforeEnter: () => {
      window.location.href = `${config.analyticsUrl}/#/${name}`;
    },
  }));
}

export default configLoading()
  .then((config) => [
    ...hazcheckDetectRoutes,
    ...caseManagementRoutes(config),
    ...analyticsRoutes(config),
    notFoundRoute,
  ]);
