import axios, { AxiosRequestConfig } from "axios";
import AuthService from "@/services/auth-service";
import { AxiosOptions } from "@/models/axios-service.d";

class AxiosService {
  authService: AuthService;

  constructor(authService: AuthService) {
    this.authService = authService;
  }

  private async headers(extraHeaders?: any): Promise<any> {
    const token = await this.authService.getToken();
    const xhcdKey = (Math.random() * 1e36).toString(36);

    return {
      ...extraHeaders,
      Authorization: `Bearer ${token}`,
      Accept: "application/hal+json",
      "X-HazcheckDetect-Key": `${xhcdKey}`
    };
  }

  async get(url: string, config?: AxiosRequestConfig): Promise<any> {
    config = {
      ...config,
      headers: await this.headers(config?.headers)
    };

    const response = await axios.get(url, config);

    return response.data;
  }

  async getFile(
    url: string,
    axiosOptions?: AxiosOptions
  ): Promise<{ fileContents: any; fileName: string }> {
    const options: AxiosRequestConfig = {
      responseType: "arraybuffer",
      headers: await this.headers(axiosOptions?.headers)
    };
    const response = await axios.get(url, options);

    let filename = "";
    const disposition = response.headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename=((\\?['"])(.*?)\2|([^;\n]*))/;
      const matches = filenameRegex.exec(disposition);
      if (matches) {
        filename = (matches[3] ?? "") + (matches[4] ?? "");
      }
    }

    return {
      fileContents: response.data,
      fileName: filename
    };
  }

  async put(url: string, payload: any, axiosOptions?: AxiosOptions): Promise<any> {
    const options = {
      headers: await this.headers(axiosOptions?.headers)
    };

    const response = await axios.put(url, payload, options);

    return response.data;
  }

  async delete(url: string, axiosOptions?: AxiosOptions): Promise<any> {
    const options = {
      headers: await this.headers(axiosOptions?.headers)
    };

    const response = await axios.delete(url, options);

    return response.data;
  }

  async post<T = any>(url: string, payload: any, axiosOptions?: AxiosOptions): Promise<T> {
    const options = {
      headers: await this.headers(axiosOptions?.headers)
    };

    const response = await axios.post<T>(url, payload, options);

    return response.data;
  }
}
export default AxiosService;
