type APMRUMAgentConfig = {
  active: boolean;
  serviceName: string;
  serviceUrl: string;
};

export const FEATURES = ["KeywordSuggestion"] as const;
export type Feature = typeof FEATURES[number];
export type FeatureKeyInConfig = Uncapitalize<Feature>;
type EnabledOrDisabled = "enabled" | "disabled";
export const flagStates = { Enabled: "enabled", Disabled: "disabled" } as const;

export type Config = {
  api: string;
  caseManagementUrl: string;
  analyticsUrl: string;
  fileUploadUrl: string;
  authentication: {
    keycloakUrl: string;
    realm: string;
    frontendClientId: string;
    backendAudience: string;
  };
  defaultPageSize: number;
  serviceHookUriProtocols: string[];
  authEndPointUriProtocols: string[];
  showCaseManagement: boolean;
  apm: APMRUMAgentConfig;
  navigation: any[];
  fileUploadDefaultMapping: any;
  features: Record<FeatureKeyInConfig, EnabledOrDisabled>;
};

const config = {
  authentication: {}
} as Config;

function lowerCaseFirstLetter([first, ...rest]: string): string {
  return [first.toLowerCase(), ...rest].join("");
}

function mapKeys(obj: any, map: (key: string) => string): any {
  if (obj instanceof Array) {
    return obj.map(x => mapKeys(x, map));
  }

  if (!(obj instanceof Object)) {
    return obj;
  }

  const keys = Object.keys(obj);

  return keys.reduce((acc, key) => {
    const val = obj[key];
    return {
      ...acc,
      [map(key)]: typeof val === "object" ? mapKeys(val, map) : val
    };
  }, {} as any);
}

async function retrieveLocalConfig(localConfigFile: string) {
  try {
    return fetch(localConfigFile).then(response => response.json());
  } catch {
    return {};
  }
}

export async function configLoading(): Promise<Config> {
  let envConfigFile = "js/config-env.json";
  const localConfigFile = "js/config.json.local";
  let fetchLocalConfigFile = Promise.resolve({} as any);

  if (window) {
    const query = new URLSearchParams(window.location.search);
    envConfigFile = query.get("config") || envConfigFile;

    const host = window.location.host;

    if (host == "localhost" || host.indexOf("localhost") == 0) {
      fetchLocalConfigFile = retrieveLocalConfig(localConfigFile);
    }
  }

  const result = (await Promise.all([
    fetch("js/config.json"),
    fetch(envConfigFile),
    fetchLocalConfigFile
  ])
    .then(([baseResponse, envResponse, localResponse]) =>
      Promise.all([baseResponse.json(), envResponse.json(), localResponse])
    )
    .then(([baseJson, envJson, localJson]) => ({ ...baseJson, ...envJson, ...localJson }))
    .then(json => mapKeys(json, lowerCaseFirstLetter))
    .then(json => Object.assign(config, json))) as Promise<Config>;
  return result;
}

export const isFeatureEnabled = (feature: Feature) => {
  const keyOfFeature = lowerCaseFirstLetter(feature) as FeatureKeyInConfig;
  return config.features && config.features[keyOfFeature] === flagStates.Enabled;
};

export default config;
