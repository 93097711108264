import AxiosService from "./axios-service";
import config from "@/config";

export interface BookingProperties{
    bookingProperties: BookingProperty[];
}

export interface BookingProperty{
    name: string;
    path: string;
}

const EXTRACT_BOOKING_PROPERTIES = "extracted-booking-properties";

export default class ConfigurationService {
 
  axios: AxiosService;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  async getExtractBookingPropeties(): Promise<BookingProperties> {
    return this.getConfig(EXTRACT_BOOKING_PROPERTIES);
  }

  private async getConfig(configType: string){
    const uri = `${config.api}/configuration/${configType}`;
    const response = await this.axios.get(uri); 
    return JSON.parse(response.json)
  }

  async saveExtractBookingPropetiesConfig(metadataConfig: BookingProperties) {
    await this.saveConfig(metadataConfig, EXTRACT_BOOKING_PROPERTIES)
  }

  private async saveConfig(configValues: any, configType: string){
    const uri = `${config.api}/configuration/${configType}`;
    const body = { json: JSON.stringify(configValues) };

    await Promise.all([
      this.axios.put(uri, body, { headers: { 'content-type': 'application/json' } }),
    ]);
  }

}
