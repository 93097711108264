import * as monaco from 'monaco-editor';

const rules = {
  comparison: [
    [/@field/, 'identifier.field'],
    [/[(){}]/, '@brackets'],
    [/\:|\.|\,/, 'delimiter'],
    { include: '@whitespace' },
    [/>|>=|<|<=|=|BEGINS|ENDS|CONTAINS|EXISTS/, 'term.operators'],
    [/"/, { token: 'term.quote', bracket: '@open', next: '@termDouble' }],
    [/'/, { token: 'term.quote', bracket: '@open', next: '@termSingle' }],
    [/\//, { token: 'term.regex.quote', bracket: '@open', next: '@regex' }],
    [/\#|\?|(~|@)(\d*\.?\d+)?/, 'term.modifier'],
    [/\b(AND|OR|NOT|XOR)\b/, 'operators.logic'],
    [/\bNEAR\b/, 'operators.near'],
  ],

  termDouble: [
    [/[^\\"]+/, 'term'],
    [/\\./, 'term.escape'],
    [/"/, { token: 'term.quote.term', bracket: '@close', next: '@pop' }],
  ],

  termSingle: [
    [/[^\\']+/, 'term'],
    [/\\./, 'term.escape'],
    [/'/, { token: 'term.quote', bracket: '@close', next: '@pop' }],
  ],

  regex: [
    [/[^\\/]+/, 'term.regex'],
    [/\\./, 'term.regex.escape'],
    [/\//, { token: 'term.regex.quote', bracket: '@close', next: '@pop' }],
  ],

  whitespace: [
    [/\s+/, 'white'],
  ],
};

monaco.languages.register({
  id: 'hdql',
});

monaco.languages.setMonarchTokensProvider(
  'hdql',
  {
    ignoreCase: true,
    defaultToken: 'invalid',

    field: /(?:\\.|[-\w$])+|\*/,

    tokenizer: rules,
  } as any,
);

monaco.languages.register({
  id: 'hdtl',
});

monaco.languages.setMonarchTokensProvider(
  'hdtl',
  {
    ignoreCase: true,
    defaultToken: 'invalid',

    field: /(?:\\.|[-\w$])+|\*/,

    tokenizer: {
      ...rules,
      ...{
        comparison: [
          [/\{\{\{/, { token: 'term.quote', bracket: '@open', next: '@termParameter' }],
          [/\{\{/, { token: 'term.quote', bracket: '@open', next: '@termParameter' }],
          ...rules.comparison,
        ],

        termParameter: [
          { include: '@whitespace' },
          [/[^\\}\\(\\)]+/, 'term.template.parameter'],
          [/\(/, { token: 'term.template.function', bracket: '@open', next: '@templateFunctionArgs' }],
          [/\}\}\}/, { token: 'term.quote', bracket: '@close', next: '@pop' }],
          [/\}\}/, { token: 'term.quote', bracket: '@close', next: '@pop' }],
        ],

        templateFunctionArgs: [
          { include: '@whitespace' },
          [/[\\{\\}]/, 'invalid'],
          [/[^\\)]+/, 'term.template.function'],
          [/,/, 'delimiter'],
          [/\)/, { token: 'term.template.function', bracket: '@close', next: '@pop' }],
        ],

      },
    },
  } as any,
);

monaco.editor.defineTheme('hdql-dark', {
  base: 'vs-dark',
  inherit: true,
  colors: {},
  rules: [
    { token: 'invalid', foreground: 'ff0000', fontStyle: 'bold' },
    { token: 'identifier.field', foreground: 'e8e084' },
    { token: 'operators.logic', foreground: 'd69d1d' },
    { token: 'operators.near', foreground: 'd69d1d' },
    { token: 'term', foreground: 'ce9178' },
    { token: 'term.template.parameter', fontStyle: 'bold', foreground: '04bd13' },
    { token: 'term.template.function', foreground: '88f791' },
    { token: 'term.quote', foreground: '7886cc' },
    { token: 'term.operators', foreground: 'd4a4c4' },
    { token: 'term.regex', foreground: 'f8b148' },
    { token: 'term.modifier', foreground: 'd4a4c4' },
    { token: 'term.escape', foreground: 'f3d4e8' },
  ],
});
