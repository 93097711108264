import axios, { AxiosResponse } from "axios";
import Vue from "vue";
import VueAxios from "vue-axios";

import { getModule } from "vuex-module-decorators";
import AppState from "@/store/modules/app-module";

const appState = getModule(AppState);

function hasPagenationErrors(messageAndProperty: { property: string }): boolean {
  return "size|page|sort".includes(messageAndProperty.property);
}

function getErrorReason(response?: AxiosResponse<any>) {
  if (response === undefined) return undefined;

  if (response.status === 400) {
    if (
      response.data &&
      "errors" in response.data &&
      response.data.errors.filter(hasPagenationErrors).length > 0
    ) {
      return "An error occurred submitting your request. Please correct the pagination parameters of the URL and try again.";
    }
    return "An error occurred submitting your request. Please correct any validation errors and try again.";
  }
  if (response.status === 401) return "Please log in and try again.";
  if (response.status === 403) return "You do not have access to perform this operation.";
  if (response.status === 500) return "The API encountered an error processing your request.";

  return `Something went wrong processing your request. ${response.statusText}`;
}

axios.interceptors.response.use(
  response => {
    appState.resetApiError();

    return Promise.resolve(response);
  },
  error => {
    if (axios.isCancel(error)) return Promise.reject(error);

    const isApiDown = error.response === undefined;
    const errorReason = getErrorReason(error.response);

    appState.setApiError({
      down: isApiDown,
      message: errorReason
    });

    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios);
