import AxiosService from "@/services/axios-service";
import config from "@/config";
import {
  KeywordSearchTerm,
  KeywordSuggestionResult,
  SortBy,
  Sort
} from "@/models/keyword-suggestion";
export default class KeywordSuggestionService {
  defaultOptions = {
    numberOfPredictions: 10,
    sortBy: "d"
  };

  axiosService: AxiosService;

  constructor(injectedAxios: AxiosService) {
    this.axiosService = injectedAxios;
  }

  async getSuggestions(
    input: string,
    sortBy: Sort,
    exclusions: string[] = [],
    options = this.defaultOptions
  ): Promise<KeywordSuggestionResult> {
    const { api } = config;

    const url = `${api}/keyword`;
    const body = {
      ...options,
      exclusions,
      input,
      sortBy
    };

    const response = await this.axiosService.post(url, body);
    const jsonResult = response as KeywordSuggestionResult;

    jsonResult.nextWords = filterOutZeroesByType(jsonResult.nextWords, sortBy);
    jsonResult.previousWords = filterOutZeroesByType(jsonResult.previousWords, sortBy);

    return jsonResult;
  }
}

const filterOutZeroesByType = (keywords: KeywordSearchTerm[], sortBy: Sort) => {
  return keywords.filter(x => {
    switch (sortBy) {
      case SortBy.Frequency:
        return x.allAppearence > 0;
      case SortBy.NonDG:
        return x.appearenceInCommodity > 0;
      case SortBy.DangerousCargo:
        return x.appearenceInDangerousCargo > 0;
    }
  });
};
