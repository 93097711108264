import config from "@/config";
import { PaginationOptions } from "@/models/hal";
import { Template, TemplateOperation, TemplateOperationList } from "@/models/library-maintenance";
import { AxiosResponse, CancelToken } from "axios";
import AxiosService from "./axios-service";
import { pagedUrl } from "./helpers";

export default class TemplateOperationService {
  axiosService: AxiosService;

  constructor(injectedAxios: AxiosService) {
    this.axiosService = injectedAxios;
  }

  private defaultPaginationOptions: PaginationOptions = {
    page: 1,
    size: config.defaultPageSize,
  };

  async readSingle(
    libraryId: string,
    templateId: string,
    operationId: string
  ): Promise<TemplateOperation> {
    const uri = `${config.api}/libraries/${libraryId}/templates/${templateId}/operations/${operationId}`;
    const result = await this.axiosService.get(uri);

    return result as TemplateOperation;
  }

  async listTemplateOperations(
    libraryId: string,
    templateId: string,
    options = this.defaultPaginationOptions,
    cancelToken? : CancelToken
  ): Promise<TemplateOperationList> {
    const uri = pagedUrl(`${config.api}/libraries/${libraryId}/templates/${templateId}/operations`, options);
    const list = await this.axiosService.get(uri, { cancelToken });

    return list as TemplateOperationList;
  }

  async requestCascadeUpdate(
    { 'library-id': libraryId, id, eTag }: Partial<Template>
  ): Promise<string> {
    const uri = `${config.api}/libraries/${libraryId}/templates/${id}/cascade-update`;

    const response: AxiosResponse = await this.axiosService.put(uri, { eTag });

    if (response.status != 202)
      return Promise.resolve("");

    return Promise.resolve(response.headers['location']);
  }
}
