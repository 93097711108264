import AxiosService from '@/services/axios-service';
import { EntityHistoryList } from '@/models/library-maintenance.d';
import { PaginationOptions } from '@/models/hal.d';
import config from '@/config';

import { pagedUrl } from './helpers';
import { CancelToken } from 'axios';

const defaultPaginationOptions: PaginationOptions = {
  page: 1,
  size: config.defaultPageSize,
};

export default class HistoryService {
  axiosService: AxiosService;

  constructor(injectedAxios: AxiosService) {
    this.axiosService = injectedAxios;
  }

  async listEntityHistory(
    entityType: string,
    entityId: string,
    options = defaultPaginationOptions,
    cancelToken?: CancelToken
  ): Promise<EntityHistoryList> {
    const uri = pagedUrl(`${config.api}/${entityType}/${entityId}/history`, options);

    try {
      const list = await this.axiosService.get(uri, { cancelToken });

      return list as EntityHistoryList;
    }
    catch {
      return null as unknown as EntityHistoryList;
    }
  }
}
