












































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { getModule } from 'vuex-module-decorators';
import AppState from '@/store/modules/app-module';

const appState = getModule(AppState);

@Component
export default class Toolbar extends Vue {
  get color() {
    return appState.apiError.down ? 'error' : undefined;
  }

  get title() {
    return appState.title;
  }

  toggleDrawer() {
    appState.toggleDrawer();
  }
}
