







































import Vue from "vue";
import { Component, Inject } from "vue-property-decorator";

import { getModule } from "vuex-module-decorators";
import AppState from "@/store/modules/app-module";

import config from "@/config";
import AuthService from "@/services/auth-service";
import VersionService from "@/services/version-service";

const appState = getModule(AppState);

@Component
export default class Drawer extends Vue {
  @Inject() VersionService!: VersionService;
  @Inject() AuthService!: AuthService;

  get drawerState() {
    return appState.drawer;
  }

  set drawerState(value) {
    appState.setDrawer(value);
  }

  get isLoggedIn() {
    return this.AuthService.isLoggedIn();
  }

  links: any[] = [];
  versionString = "";

  async mounted() {
    this.links = await this.getAuthorizedLinks(config.navigation);
    this.versionString = await this.VersionService.apiVersion();
  }

  async getAuthorizedLinks(links: any[]) {
    const permissions = await this.AuthService.getUserPermissions();

    if (!permissions) return links.filter(link => !link.permission);

    return links.filter(link => !link.permission || permissions.isAuthorized(link.permission));
  }

  login() {
    const redirect = this.$route.query.redirect as string;
    this.AuthService.login(redirect);
  }
}
