import AxiosService from "./axios-service";
import config from "@/config";
import AuthService from "./auth-service";

export default class FileUploadService {
  axios: AxiosService;
  authService: AuthService;

  constructor(axios: AxiosService, authService: AuthService) {
    this.axios = axios;
    this.authService = authService;
  }

  async SendScreeningBatch(
    columnHeadings: string[],
    data: string[][],
    policyIds: string[],
    mapping: any = null
  ): Promise<ScreeningBatchItem[]> {
    const payload = {
      columnHeadings,
      data,
      mapping: mapping ?? config.fileUploadDefaultMapping,
      policyIds,
      hazcheckDetectUrl: config.api,
      customer: config.authentication.realm,
      environment: config.authentication.frontendClientId.split("-")[0]
    };

    return await this.axios.post<ScreeningBatchItem[]>(config.fileUploadUrl, payload);
  }

  async WakeUp() {
    return await this.axios.get(config.fileUploadUrl);
  }
}

export type ScreeningBatchItem = {
  'reference': string;
  'screening_id': string;
  'status_code': number;
  'response_body': any;
};
