import { configLoading } from "@/config";

import LibraryService from "./library-service";
import RuleService from "./rule-service";
import AxiosService from "./axios-service";
import ServiceHookService from "./service-hook-service";
import PolicyService from "./policy-service";
import EntityService from "./entity-service";
import AuthService from "./auth-service";
import ScreenResultsService from "./screen-results-service";
import TemplateService from "./template-service";
import TemplateOperationService from "./template-operation-service";
import VersionService from "./version-service";
import HistoryService from "./history-service";
import ConfigurationService from "./configuration-service";
import FileUploadService from "./file-upload-service";
import KeywordSuggestionService from "./keyword-suggestion-service";
import EvaluationService from "./evaluation-service";

type Services = {
  AuthService: AuthService;
  LibraryService: LibraryService;
  RuleService: RuleService;
  ServiceHookService: ServiceHookService;
  PolicyService: PolicyService;
  ScreenResultsService: ScreenResultsService;
  TemplateService: TemplateService;
  TemplateOperationService: TemplateOperationService;
  VersionService: VersionService;
  HistoryService: HistoryService;
  ConfigurationService: ConfigurationService;
  FileUploadService: FileUploadService;
  KeywordSuggestionService: KeywordSuggestionService;
  EvaluationService: EvaluationService;
};

const services: Services = {} as any;

export const servicesLoading = configLoading().then((config) => {
  const authService = new AuthService(config);
  const axiosService = new AxiosService(authService);
  const entityService = new EntityService(axiosService);

  Object.assign(services, {
    AuthService: authService,
    LibraryService: new LibraryService(axiosService, entityService),
    RuleService: new RuleService(axiosService, entityService),
    ServiceHookService: new ServiceHookService(axiosService),
    PolicyService: new PolicyService(axiosService),
    ScreenResultsService: new ScreenResultsService(axiosService),
    TemplateService: new TemplateService(axiosService),
    TemplateOperationService: new TemplateOperationService(axiosService),
    VersionService: new VersionService(axiosService),
    HistoryService: new HistoryService(axiosService),
    ConfigurationService: new ConfigurationService(axiosService),
    FileUploadService: new FileUploadService(axiosService, authService),
    KeywordSuggestionService: new KeywordSuggestionService(axiosService),
    EvaluationService: new EvaluationService(axiosService)
  } as any);

  return services;
});

export default services;
