import AxiosService from '@/services/axios-service';
import config from '@/config';

export default class EntityService {
  axiosService: AxiosService;

  constructor(injectedAxios: AxiosService) {
    this.axiosService = injectedAxios;
  }

  async enableDisable(reference: string, enable: boolean): Promise<any> {
    const action = enable ? 'enable' : 'disable';
    const uri = `${config.api}/${reference}/${action}`;
    return this.axiosService.put(uri, null);
  }
}
