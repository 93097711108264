import { Config } from "@/config";
import Keycloak, { KeycloakConfig, KeycloakInitOptions, KeycloakLoginOptions } from "keycloak-js";
import axios from "axios";

export type User = {
  profile: {
    sub?: string;
    name?: string;
    email?: string;
  };
};
export type AuthUser = User & {
  Permissions: string[];
};

interface UmaConfiguration {
  token_endpoint: string;
}

export interface Permission {
  rsname: string;
  scopes: string[];
}
export interface TokenResponse {
  access_token: string;
  expires_in: number;
}
export interface UmaToken {
  access_token: string;
  expires: number;
}

export class PermissionMatcher {
  constructor(private permissions: Permission[]) {}

  public isAuthorized(permission: string): boolean {
    const parts = permission.split(":");
    const scope = parts[0];
    const resource = parts[1];

    if (!this.permissions) {
      return false;
    }

    function eq(a: string, b: string): boolean {
      return a.localeCompare(b, undefined, { sensitivity: "accent" }) == 0;
    }

    return this.permissions.some(p => eq(p.rsname, resource) && p.scopes.some(s => eq(s, scope)));
  }
}

export default class AuthService {
  private config: Config;
  private keycloak: Keycloak;
  private initialized?: Promise<boolean>;
  private permissions?: PermissionMatcher;
  private umaConfig?: UmaConfiguration;
  private umaToken?: UmaToken;

  constructor(config: Config) {
    this.config = config;
    const keycloakConfig: KeycloakConfig = {
      url: `${config.authentication.keycloakUrl}/auth`,
      realm: config.authentication.realm,
      clientId: config.authentication.frontendClientId
    };

    this.keycloak = new Keycloak(keycloakConfig);
  }

  public init(): Promise<boolean> {
    const keyclockInitOptions: KeycloakInitOptions = {
      onLoad: "check-sso",
      enableLogging: true,
      scope: "hazcheck-detect",
      pkceMethod: "S256"
    } as any;

    this.keycloak.onAuthLogout = () => (this.permissions = undefined);
    this.keycloak.onAuthError = () => (this.permissions = undefined);
    this.keycloak.onAuthRefreshError = () => (this.permissions = undefined);
    this.keycloak.onTokenExpired = () =>
      this.keycloak
        .updateToken(30)
        .then((refreshed: boolean) => {
          if (!refreshed) {
            this.permissions = undefined;
          }
        })
        .catch(() => (this.permissions = undefined));

    this.initialized = new Promise((resolve, reject) => {
      this.keycloak
        .init(keyclockInitOptions)
        .then(authenticated => resolve(authenticated))
        .catch(error => reject(error));
    });

    return this.initialized;
  }

  public async getUserPermissions(): Promise<PermissionMatcher> {
    if (!this.keycloak.authenticated) {
      return new PermissionMatcher([]);
    }

    if (!this.permissions) {
      const response = await this.makeUmaRequest<Permission[]>({
        response_mode: "permissions"
      });
      this.permissions = new PermissionMatcher(response);
    }
    return this.permissions;
  }

  public async getUser(): Promise<User | null> {
    if (!this.keycloak.authenticated) return null;

    const user = {
      profile: {
        sub: this.keycloak.idTokenParsed?.sub,
        name: this.keycloak.idTokenParsed?.name,
        email: this.keycloak.idTokenParsed?.email
      }
    };

    return user || null;
  }

  public async getToken(): Promise<string | undefined> {
    if (!this.keycloak.authenticated) return undefined;

    const expires = this.umaToken?.expires;
    const now = new Date().getTime();
    const buffer = 10000; // 10 seconds
    const expiring = expires && expires < now + buffer;

    if (!this.umaToken || expiring) {
      const response = await this.makeUmaRequest<TokenResponse>({});

      this.umaToken = {
        access_token: response.access_token,
        expires: now + response.expires_in * 1000
      };
    }

    return Promise.resolve(this.umaToken.access_token);
  }

  public async login(redirect?: string): Promise<void> {
    const url = window.location.origin + "/#" + (redirect || "");

    const keyclockLoginOptions: KeycloakLoginOptions = {
      redirectUri: url
    };

    return this.keycloak.login(keyclockLoginOptions);
  }

  public async logout(): Promise<void> {
    return this.keycloak.logout();
  }

  public isLoggedIn(): boolean {
    return this.keycloak.authenticated || false;
  }

  public IsExpired(): boolean {
    return this.keycloak.isTokenExpired();
  }

  private async makeUmaRequest<T>(options: { [key: string]: string }): Promise<T> {
    const umaConfig = await this.loadUmaConfiguration();

    const params = new URLSearchParams();

    params.append("grant_type", "urn:ietf:params:oauth:grant-type:uma-ticket");
    params.append("audience", this.config.authentication.backendAudience);

    for (const key of Object.keys(options)) {
      params.append(key, options[key]);
    }

    const headers = {
      Authorization: `Bearer ${this.keycloak.token}`,
      "Content-Type": "application/x-www-form-urlencoded"
    };

    const response = await axios.post<T>(umaConfig.token_endpoint, params, { headers });

    return response.data;
  }

  private async loadUmaConfiguration(): Promise<UmaConfiguration> {
    if (!this.umaConfig) {
      const url = this.keycloak.authServerUrl;
      const realm = this.keycloak.realm;
      const umaConfigUrl = `${url}/realms/${realm}/.well-known/uma2-configuration`;

      const response = await axios.get<UmaConfiguration>(umaConfigUrl);

      this.umaConfig = response.data;
    }
    return this.umaConfig;
  }
}
