import { PaginationOptions } from '@/models/hal.d';
import uriTemplate from 'url-template';
import config from '@/config';

export function pagedUrl(uri: string, options: PaginationOptions): string {
  const { defaultPageSize } = config;
  const template = uriTemplate.parse(`${uri}{?page,size,sort*}`);

  const { page = 1, size = defaultPageSize, sort = [] } = options;

  return template.expand({ page, size, sort });
}

export default false;
