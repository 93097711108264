import { PaginationOptions } from '@/models/hal.d';
import { ScreenMetadataList, ScreenResult } from '@/models/screen-result.d';
import config from '@/config';
import uriTemplate from 'url-template';
import AxiosService from './axios-service';
import { CancelToken } from 'axios';

export default class ScreenResultsService {
  axiosService: AxiosService;

  constructor(injectedAxios: AxiosService) {
    this.axiosService = injectedAxios;
  }

  async listScreenResults(
    options: PaginationOptions,
    filter?: string,
    from?: Date,
    to?: Date,
    hitsOnly?: boolean,
    cancelToken?: CancelToken
  ): Promise<ScreenMetadataList> {
    const { defaultPageSize, api } = config;
    const template = uriTemplate.parse(`${api}/screen-results/{?page,size,sort*,filter,,from,to}`);

    const uri = template.expand({
      ...{ page: 1, size: defaultPageSize, sort: [] },
      ...options,
      filter: filter && filter.length ? filter : undefined,
      from: from && from.toISOString(),
      to: to && to.toISOString()
    });

    const list = await this.axiosService.get(uri,{ cancelToken });

    return list as ScreenMetadataList;
  }

  async readSingle(id: string): Promise<ScreenResult> {
    const uri = `${config.api}/screen-results/${id}`;

    const result = await this.axiosService.get(uri, { headers: { version: 2 } });

    return result as ScreenResult;
  }

  async readScreening(id: string): Promise<string> {
    const uri = `${config.api}/screen/${id}`;

    const screening = await this.axiosService.get(uri);

    return screening as string;
  }
}
