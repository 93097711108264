import { ThemeOptions } from 'vuetify/types/services/theme.d';

// Color palette:

// These colors are derived from:
// https://mycolor.space/?hex=%23E30513&sub=1
// where the color #e30513 comes from the red in the Hazcheck logo.
// The name of the colors are derived from:
// http://chir.ag/projects/name-that-color

const greenHaze = '#009771'; // Slightly-pastal green.
const grey = '#808080'; // Same as CSS grey.
const japaneseLaurel = '#008f0a'; // Slightly-vivid green.
const monza = '#e30513'; // Vivid red.
const pacificBlue = '#0099c2'; // Slightly darker cyan-like blue.
const roseOfSharon = '#b15f00'; // Warm orange-brown.

const theme: ThemeOptions = {
  themes: {
    light: {
      // Standard theme color names, defaulted to colors from the chosen palette:
      primary: pacificBlue,
      secondary: pacificBlue,
      tertiary: pacificBlue,
      accent: japaneseLaurel,
      info: japaneseLaurel,
      success: japaneseLaurel,
      warning: roseOfSharon,
      error: monza,
      anchor: greenHaze,

      // Extra theme colors
      disabled: grey,
    },
  },
};

export default theme;
