import AxiosService from "./axios-service";
import config from "@/config";

export interface EvaluationResult {
  matches: Match[];
}

export interface Match {
  keyword: string;
  path: string;
  length: number;
  position: number;
  matchedText: string;
}

export default class EvaluationService {
  constructor(injectedAxios: AxiosService) {
    this.axiosService = injectedAxios;
  }
  axiosService: AxiosService;

  async evaluateCondition(condition: string, SUT: string): Promise<EvaluationResult> {
    return await this.axiosService.post(`${config.api}/evaluate`, {
      condition: condition,
      sut: SUT
    });
  }
}
